<template>
  <div>
    <TopMenuSubheadline :title="title" />
    <div class="app-content-box bg-gray">
    <div class="wrapper mt-5 mb-5">
      <div class="container text-center" v-if="isLoaded">
        <div class="history-notification">{{plan.name}}</div>
        <div class="history-summary">Plan ukończony w {{to_go}}%</div>
        <div class="history-notification mt-3 mb-2">Rodzaj planu</div>
        <div class="history-summary">
          <span v-if="plan.type === 'time'">czasowy</span>
          <span v-if="plan.type === 'books'">książkowy</span>
          <span v-if="plan.type === 'pages'">stronicowy</span>
        </div>
        <div class="history-notification mt-3 mb-2">Suma przeczytanych stron</div>
        <div class="history-summary">{{plan.pages_readed}}</div>
        <div class="history-notification mt-3 mb-2">Czas spędzony nad książkami</div>
        <div class="history-summary">{{Math.floor(plan.time_readed / 60)}} h</div>
        <div class="history-notification mt-3 mb-2">Całkowity czas trwania planu czytelniczego</div>
        <div class="history-summary">{{full_training_time}} h</div>
        <div class="history-notification mt-3 mb-2">Liczba książek w planie</div>
        <div class="history-summary">{{plan.books_total}}</div>
        <div class="mt-3 mb-3">
          <v-calendar
            ref="calendar"
            is-expanded
            :attributes="attr"
            mode="range"
            class="text-muted"
          />
        </div>
      </div>
      <div v-else><Loader /></div>
    </div>
  </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import Loader from '../components/Loader'
export default {
  name: 'TrainingPlanHistoryMore',
  components: {
    TopMenuSubheadline,
    Loader
  },
  props: {
    id: String
  },
  data () {
    return {
      title: 'Historia planu czytelniczego',
      isLoading: true,
      plan: [],
      progress: [],
      full_training_time: '',
      today: 0,
      start: 0,
      starttest: 0,
      to_go: '',
      moveToDay: 0,
      moveToMonth: 0,
      moveToYear: 0,
      isLoaded: false
    }
  },
  async created () {
    await this.$https('/plan/' + this.id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.plan = data.response
        this.today = new Date()
        this.today = Date.parse(this.today) / 1000
        const start = new Date(this.plan.created_at)
        this.start = Date.parse(start) / 1000
        let formattedDate = this.plan.created_at.slice(0, 4) + '/' + this.plan.created_at.slice(5, 7) + '/' + this.plan.created_at.slice(8, 10) + ' ' + this.plan.created_at.slice(11, this.plan.created_at.length)
        formattedDate = Date.parse(formattedDate + '-0500') / 1000
        this.full_training_time = Math.round((this.today - formattedDate) / (60 * 60 * 24))
        this.to_go = 100 - parseInt(this.plan.to_go)
        this.plan.sessions.forEach(e => {
          const year = e.from.slice(0, 4)
          const month = parseInt(e.from.slice(5, 7)) - 1
          const day = e.from.slice(8, 10)
          this.progress.push({
            id: e.id,
            date: new Date(year, month, day),
            class: 'bg-dark circle',
            contentClass: 'text-white'
          })
        })
        this.moveToDay = parseInt(this.plan.sessions[0].from.slice(8, 10))
        this.moveToMonth = parseInt(this.plan.sessions[0].from.slice(5, 7))
        this.moveToYear = parseInt(this.plan.sessions[0].from.slice(0, 4))
        this.isLoaded = true
      })
      .catch(error => {
        console.log('', error)
      })
    const calendar = this.$refs.calendar
    await calendar.move({ month: this.moveToMonth, year: this.moveToYear })
  },
  computed: {
    attr () {
      return this.progress.map(t => ({
        key: `progress.${t.id}`,
        highlight: {
          class: t.class,
          contentClass: t.contentClass
        },
        dates: t.date,
        customData: t
      }))
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .trainings-links {
  list-style-type: none;
  display: flex;
  width: 70%;
  justify-content: space-around;
}
.links-heading {
  font-size: 13px;
  text-align: center;
}

.history-list-wrapper {
  display: flex;
  align-items: center;
}
.history-list-icon {
  width: 45px;
  height: 45px;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.history-notification {
  font-weight: 700;
  line-height: 32px;
  font-size: 25px;
}
.history-summary {
  font-size: 16px;
}
</style>
